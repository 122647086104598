export type NavigateToUrlHandler = (url: string) => void;
export type GreenfieldNavigationMessage = {
  data: {
    location?: string,
    greenfieldBridge?: true,
    navigation?: true
  }
}

export class GreenfieldBridge {

  /**
   * Create a new GreenfieldBridge instance.
   * Instance can be initialized directly by passing in
   * the URL of the Greenfield app, or later by calling
   * the init() function with the URL instead.
   * The latter is useful for Angular Dependency Injection usage.
   *
   * @param greenfieldOrigin should be the full protocol+host+(optional)port ex: 'https://app.terminus.com'
   */
  constructor(
    greenfieldOrigin?: string,
    forceFraming?: boolean,
    navigateToUrl?: (url: string) => void,
    // used for injection of fakes in tests
    private _window = window
  ) {
    if (greenfieldOrigin && navigateToUrl) this.init(greenfieldOrigin, forceFraming ?? false, navigateToUrl);
  }

  /**
   * Target origin for postMessage to Greenfield app
   */
  private greenfieldOrigin: string;

  private navigateToUrl: NavigateToUrlHandler;

  /**
   * Signifies whether the Green/Brownfield connection exists.
   * true => Brownfield app is wrapped in Greenfield
   * false => Brownfield app is loaded free-standing
   *
   * Note: throws Error if init() has not been called
   */
  get isConnected() {
    this.verifyInitialized();
    return this._isConnected;
  }
  private set isConnected(val: boolean) {
    this._isConnected = val;
  }
  _isConnected = false;

  /**
   * Notify Greenfield of a navigation event
   * returns true if communicated to Greenfield, false otherwise.
   * Use as a shortcut for checking for connection:
   *
   * greenfieldBridge.navigate(url) || ( window.location.href = url );
   */
  navigate = (location: string, type?: 'popState' | 'pushState' | 'replaceState'): boolean => {
    if (!this.isConnected) return false

    this._window.parent.postMessage({
      greenfieldBridge: true, navigation: true,
      type: type || (this.isExternalUrl(location) ? 'external' : 'internal'),
      title: this._window.document.title,
      location: `${location}`.replace(this._window.location.origin, '')
    }, '*');

    return true;
  }

  /**
   * Initialize the Bridge connection
   * @param greenfieldOrigin should be the full protocol+host+(optional)port ex: 'https://app.terminus.com'
   */
  init = (greenfieldOrigin: string, forceFraming: boolean, navigateToUrl: NavigateToUrlHandler) => {
    // if origin not provided, assume no connection.
    if (!greenfieldOrigin) return;

    // register the location, cleaning it a bit just in case.
    this.greenfieldOrigin = new URL(greenfieldOrigin).origin;
    this.navigateToUrl = navigateToUrl;

    // see if we are inside iframe we can communicate with
    try {
      if (this._window.self !== this._window.top) {

        this._window.addEventListener('message', this.handleMessageFromGreenfield);

        // Hijack clicks to external links not opening in a new tab
        // and send them up to the Greenfield app for handling.
        this._window.addEventListener('click', this.handleLinkClick);

        // listen for popstate events (back button) and send those to
        // the Greenfield app for handling
        this._window.addEventListener('popstate', this.handlePopState);

        // hijack history.pushState & replaceState calls and send them to
        // the Greenfield app for handling
        this._window.history.pushState = new Proxy(window.history.pushState, {
          apply: /* istanbul ignore next */ (target, thisArg, argArray: Parameters<History['pushState']>) => {
            this.navigate(`${argArray[2]}`, 'pushState');
            return target.apply(thisArg, argArray);
          },
        });
        this._window.history.replaceState = new Proxy(window.history.replaceState, {
          apply: /* istanbul ignore next */ (target, thisArg, argArray: Parameters<History['replaceState']>) => {
            this.navigate(`${argArray[2]}`, 'replaceState');
            return target.apply(thisArg, argArray);
          },
        });

        // Send the initial URL up to the Greenfield app
        // for handling, as we may have gone through some
        // routing and redirects before rendering
        this.navigate(this._window.location.href);

        // mark the bridge as being connected.
        this.isConnected = true;
      } else if (forceFraming) {
        this._window.location.host = new URL(greenfieldOrigin).host
      }
    } catch (ex) {
      /* do nothing: in an iframe I can't communicate with */
      this.isConnected = false;
    }
  }

  /**
   * Internal handler for hijacking link clicks
   */
  handleLinkClick = (ev: MouseEvent) => {
    const node = ev.target;
    if (node instanceof HTMLAnchorElement) {
      const location = `${node.getAttribute('href')}`;
      if (this.isExternalUrl(location)
        && !node.matches('[target]')
      ) {
        this.navigate(location);
        ev.stopPropagation();
        ev.preventDefault();
      }
    }
  }

  /**
   *
   */
  handlePopState = () => {
    this.navigate(this._window.location.href.replace(this._window.location.origin, ''), 'popState');
  }

  handleMessageFromGreenfield = ({ data }: GreenfieldNavigationMessage) => {
    // ignore messages not meant for greenfieldBridge navigation
    if (!(data.greenfieldBridge && data.navigation && data.location)) return;
    this.navigateToUrl(data.location);
  }

  /**
   * Note: Internal utility method
   * Throws Error if the bridge has not been initialized using init()
   */
  verifyInitialized() {
    if (!this.greenfieldOrigin) throw Error('You must call init() on GreenfieldBridge before calling notifyOfNavigation()');
  }

  /**
   * Note: Internal utility method
   * Determines if the provided URL is an external link
   */
  isExternalUrl = (url: string): boolean => {
    return Boolean(url.match(/https?:\/\//) && !url.startsWith(this._window.location.origin));
  }

}
